var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-top-info border-3 box-shadow-3 rounded-bottom"},[_c('filter-slot',{attrs:{"filter":_vm.filterValidation,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"send-multiple-sms":false},on:{"reload":function($event){return _vm.$refs['refClientsList'].refresh()}}},[_c('b-table',{ref:"refClientsList",attrs:{"slot":"table","no-provider-filtering":"","api-url":_vm.clientRoute,"items":_vm.myProvider,"fields":_vm.arrayColumns,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('router-link',{class:_vm.textLink,attrs:{"to":{
              name: _vm.$route.meta.leadRoute,
              params: { id: data.item.lead_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]),(data.item.mobile)?_c('div',[_vm._v(_vm._s(data.item.mobile))]):_vm._e()],1)]}},{key:"cell(seller_name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('span',[_vm._v(_vm._s(data.item.seller_name))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.date)))])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start"},[_c('span',{staticClass:"ncr-leads-status-successful w-100",staticStyle:{"color":"white","border-radius":"30px","padding-left":"15px","padding-right":"15px"},style:(("background-color:" + (_vm.statusColor(data.item.status_id))))},[_vm._v(_vm._s(data.item.status))])])]}},{key:"cell(question_id)",fn:function(data){return [(data.item.question_id == null)?_c('feather-icon',{staticClass:"font-medium-4",staticStyle:{"cursor":"pointer","font-size":"18px"},attrs:{"icon":"HelpCircleIcon"},on:{"click":function($event){return _vm.openModalQuestionnaire(
              data.item.score_id,
              data.item.lead_name,
              data.item.status_id,
              data.item.question_id
            )}}}):_c('feather-icon',{staticClass:"font-medium-4",staticStyle:{"cursor":"pointer","font-size":"18px","color":"green"},attrs:{"icon":"HelpCircleIcon"},on:{"click":function($event){return _vm.openModalQuestionnaire(
              data.item.score_id,
              data.item.lead_name,
              data.item.status_id,
              data.item.question_id
            )}}})]}},{key:"cell(tracking)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"font-medium-4",staticStyle:{"cursor":"pointer"},attrs:{"icon":"ListIcon"},on:{"click":function($event){return _vm.openTrackingStatus(data.item.score_id, data.item.lead_name)}}})],1)]}},{key:"cell(cr)",fn:function(data){return [(_vm.status == 1)?_c('div',[(data.item.cr == 1)?_c('router-link',{attrs:{"to":{
              name: _vm.$route.meta.reportLeadRoute,
              params: {
                idfile: data.item.score_id,
                idlead: data.item.lead_id,
                modul: 2,
                global: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                },
              },
            },"target":"_blank"}},[_c('img',{style:({ filter: _vm.skinApp == 'dark' ? 'invert(1)' : '' }),attrs:{"src":_vm.assetsImg + '/images/icons/report2.ico'}})]):_vm._e()],1):_vm._e(),(_vm.status == 2)?_c('div',[(data.item.route_html)?_c('a',{attrs:{"href":data.item.route_html,"target":"_blanck"}},[_c('amg-icon',{staticStyle:{"color":"blue"},attrs:{"size":"23","icon":"CRInvalidIcon"}})],1):_vm._e()]):_vm._e()]}},{key:"cell(route_pdf)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[(data.item.route_pdf)?_c('a',{attrs:{"href":data.item.route_pdf,"target":"_blanck"}},[(data.item.route_pdf)?_c('img',{attrs:{"width":"20","height":"20","src":_vm.assetsImg + '/images/icons/pdf.png'}}):_vm._e()]):_vm._e()])]}},{key:"cell(attemps)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.attemps)
                .slice()
                .reverse()),function(attemp,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_c('img',{attrs:{"src":_vm.assetsImg + attemp.plataform_ico,"title":attemp.plataform_name}})])}),0)])]}},{key:"cell(marked_pdf)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[(JSON.parse(data.item.attemps).slice()[0].file_cancel)?_c('a',{attrs:{"href":JSON.parse(data.item.attemps).slice()[0].file_cancel,"target":"_blanck"}},[_c('img',{staticStyle:{"width":"20px"},attrs:{"src":_vm.assetsImg + '/images/icons/pdf.png'}})]):_c('div',{staticStyle:{"height":"20px"}})])]}}])})],1),(_vm.modalTrackingStatus)?_c('modal-tracking-status',{attrs:{"modalTrackingStatus":_vm.modalTrackingStatus,"score_id":_vm.score_id,"lead_name":_vm.lead_name},on:{"closeTrackingStatus":_vm.closeTrackingStatus}}):_vm._e(),(_vm.modalQuestionnaire)?_c('modal-questionnaire',{attrs:{"modalQuestionnaire":_vm.modalQuestionnaire,"global":_vm.currentUser,"score_id":_vm.score_id,"lead_name":_vm.lead_name,"question_id":_vm.question_id,"status_id":_vm.status_id},on:{"closeModalQuestionnaire":_vm.closeModalQuestionnaire,"updateGrid":_vm.updateGrid}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }